// TODO ReactDOM no default export (fix)
// @ts-ignore
import { useState, useEffect } from 'react';
import './App.css';
import "./index.css";

import { DeepChat } from "deep-chat-react";
// const AI_MODEL_URL = 'http://localhost:7052/ask' //161.97.115.177
let messages: any[] = [
  {
    role: "ai", text: "🌿 歡迎來到健康管理助手！我是您的智能諮詢夥伴，致力於提供關於常見疾病、健康生活方式的專業建議。無論是症狀分析、疾病預防，或是運動與飲食的指南，我都樂意助您一臂之力。\n" +
      "\n" +
      "🔍 您可以隨時詢問關於疾病的原因、症狀管理，或是如何透過日常生活中的小改變來提升整體健康。請隨時告訴我您的疑問，我會盡我所能提供最合適的建議和資訊。\n" +
      "\n" +
      "🌟 讓我們一起邁向更健康的生活方式吧！\n"
  }
];

function App() {

  // Get the current URL
  const currentUrl = window.location.href;

  // Create a URL object from the current URL
  const url = new URL(currentUrl);

  // Check if the 'dev-debug' parameter is set
  const devDebug = url.searchParams.get('dev-debug');


  const [lastCypherQuery, setLastCypherQuery] = useState('');
  useEffect(() => {
    return () => {
      messages = [];
    };
  }, []);
  const messageStyles = {
    default: {
      shared: {
        bubble: {
          maxWidth: '100%',
          backgroundColor: 'unset',
          marginTop: '10px',
          marginBottom: '10px',
        },
      },
      user: {
        bubble: {
          marginLeft: '0px',
          color: 'black',
        },
      },
      ai: {
        innerContainer: {
          borderRadius: '15px',
          backgroundColor: 'white',
        },
      },
    },
  };

  const avatars = {
    default: {
      src: 'user-prompt-logo.png',
      styles: {
        position: 'left',
        container: {
          marginLeft: '12px',
          marginRight: '5px',
        },
      },
    },
    ai: {
      src: 'gpt-response-logo.png',
      styles: {
        position: 'left',
        avatar: {
          paddingTop: '6px',
        },
      },
    },
  };

  const speechToText = {
    button: {
      default: {
        container: {
          default: {
            bottom: '1em',
            right: '0.6em',
            borderRadius: '20px',
            width: '1.9em',
            height: '1.9em',
          },
          svg: {
            styles: {
              default: {
                bottom: '0.35em',
                left: '0.35em',
              },
            },
          },
        },
      },
      position: 'inside-right',
    },
  };

  const submitButtonStyles = {
    position: 'outside-right',
    submit: {
      container: {
        default: {
          bottom: '0.75em',
          borderRadius: '25px',
          padding: '5px',
          backgroundColor: '#f3f6fc',
        },
        hover: {
          backgroundColor: '#b0deff4f',
        },
        click: {
          backgroundColor: '#b0deffb5',
        },
      },
      svg: {
        content: `<?xml version="1.0" encoding="utf-8"?>
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="m21.426 11.095-17-8A.999.999 0 0 0 3.03 4.242L4.969 12 3.03 19.758a.998.998 0 0 0 1.396 1.147l17-8a1 1 0 0 0 0-1.81zM5.481 18.197l.839-3.357L12 12 6.32 9.16l-.839-3.357L18.651 12l-13.17 6.197z"/>
        </svg>`,
        styles: {
          default: {
            width: '1.5em',
            filter: 'brightness(0) saturate(100%) invert(10%) sepia(86%) saturate(6044%) hue-rotate(205deg) brightness(100%) contrast(100%)',
          },
        },
      },
    },
    loading: {
      svg: {
        styles: {
          default: {
            filter: 'brightness(0) saturate(100%) invert(72%) sepia(0%) saturate(3044%) hue-rotate(322deg) brightness(100%) contrast(96%)',
          },
        },
      },
    },
    stop: {
      container: {
        hover: {
          backgroundColor: '#ededed',
        },
      },
      svg: {
        styles: {
          default: {
            filter: 'brightness(0) saturate(100%) invert(59%) sepia(0%) saturate(0%) hue-rotate(348deg) brightness(96%) contrast(93%)',
          },
        },
      },
    },
  };

  const updateCypherDisplay = async (result: any, signals: any) => {
    if (result?.answer?.toLowerCase().indexOf('Sorry') !== -1) {
      //translate to chinesse
      await signals.onResponse({ text: '🥲很抱歉，目前關於這個主題，我暫時無法提供相關資訊。是否有其他問題或主題需要協助呢？或許我能在其他方面幫得上忙。' })
    }
    else if (result?.intermediate_steps[1]?.context?.length == 0) {
      await signals.onResponse({ text: '🥲很抱歉，目前關於這個主題，我暫時無法提供相關資訊。是否有其他問題或主題需要協助呢？或許我能在其他方面幫得上忙。' })
    }
    else {
      await signals.onResponse({ text: result?.answer })
    }

    await setLastCypherQuery(result?.intermediate_steps[0]?.query)
  }

  return (
    <div className={devDebug === 'true' ? `Appd grid grid-cols-3 gap-4` : `Appd`}>
      <div className={devDebug === 'true' ? `col-span-2` : ``}>
        <DeepChat
          textToSpeech={false}
          textInput={{
            styles: {
              container: {
                borderRadius: '20px',
                border: '1px solid #969696',
                boxShadow: 'unset',
                width: '78%',
                marginLeft: '-15px',
              },
              text: {
                padding: '10px',
                paddingLeft: '15px',
                paddingRight: '34px',
              }
            },
            placeholder: { "text": "歡迎使用醫療聊天機器人", "style": { "color": "#bcbcbc" } }
          }}
          style={{
            width: devDebug === 'true' ? '80vw' : '100vw',
            height: `calc(100vh - 10px)`,
            alignSelf: 'center',
            borderRadius: '10px',
            borderColor: '#e4e4e4',
            background: '#f3f6fc'
          }}
          initialMessages={messages}
          onNewMessage={({ message, isInitial }) => { if (!isInitial) messages.push(message); }}
          //@ts-ignore
          avatars={avatars}
          //@ts-ignore
          submitButtonStyles={submitButtonStyles}
          displayErrors={{ service: false }}
          messageStyles={messageStyles}
          //@ts-ignore
          speechToText={speechToText}
          request={{
            handler: (body, signals) => {
              try {
                let myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                let raw = JSON.stringify({
                  "question": body.messages[0].text
                });

                let requestOptions: object = {
                  method: 'POST',
                  headers: myHeaders,
                  body: raw,
                  redirect: 'follow'
                };

                fetch('/ask', requestOptions)
                  .then(response => response.json())
                  .then(result => {

                    updateCypherDisplay(result, signals)
                    //  const contextValue = result?.intermediate_steps[1]?.context || 0                             
                    //  signals.onResponse({text: result?.answer})
                  })
                  .catch(error => {
                    console.log('error', error)
                  });

              } catch (e) {
                signals.onResponse({ error: '錯誤' }); // displays an error message
              }
            }
          }}

        />
      </div>

      {devDebug === 'true' && <div>
        <div style={{
          borderColor: '#e4e4e4',
          background: '#f3f6fc',
          borderWidth: 1,
          borderRadius: 5,
          height: 200,
          width: '90%',
          padding: 10,
        }}>{lastCypherQuery}</div>
      </div>
      }

    </div>
  );
}

export default App;
